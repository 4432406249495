<template>
  <div class="free-trial-success">
    <div class="free-trial-success__container">
      <img
        src="@/assets/icons/success.svg"
        class="free-trial-success__container--image"
      />
      <h1 class="free-trial-success__container--title">Thank you!</h1>

      <p class="free-trial-success__container--description">
        Congratulations on your 7-day trial! Explore our features and benefits,
        and reach out for any assistance.
      </p>
      <button class="free-trial-success__container--btn" @click="redirectToDiscover()">Continue</button>
    </div>
  </div>
</template>


<script>
export default {
  methods: {
    redirectToDiscover() {
      this.$router.push('discover')
    },
  }
}
</script>

<style lang="scss" scoped>
.free-trial-success {
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  &__container {
    max-width: 50rem;
    width: 100%;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
    background-image: url("../../assets/icons/Confetti.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 10%;
    background-size: auto;
    @include respond(phone-large) {
      background-size: contain;
    }
    &--image {
      width: 8rem;
      height: auto;
    }
    &--title {
      text-align: center;
      font-size: 2.4rem;
      font-family: $font-primary-bold;
      margin-bottom: 1.8rem;
      margin-top: 5.4rem;
    }
    &--description {
      text-align: center;
      font-size: 1.6rem;
      font-family: $font-primary;
      font-weight: 400;
      opacity: 0.8;
      line-height: 2.2rem;
      color: #373737;
      margin-bottom: 0;
    }
    &--btn {
      color: $color-white;
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
      border: none;
      outline: none;
      background-color: $color-primary;
      width: 16rem;
      margin-top: 5.4rem;
      height: 4.5rem;
      cursor: pointer;
    }
  }
}
</style>
